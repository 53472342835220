import { useThemeHook } from '@/hooks/useThemeHook'
import { css } from '@emotion/core'
import React, { FC } from 'react'
import { HamburgerSlider } from 'react-animated-burgers'
import styles from './styles'

type HamburgerProps = {
  isActive: boolean
  toggleActive: () => void
}

export const Hamburger: FC<HamburgerProps> = ({ isActive, toggleActive }) => {
  const theme = useThemeHook()
  return (
    <div css={styles.hamburgerBackground}>
      <HamburgerSlider
        css={css`
          outline: none;
        `}
        barColor={theme.navigation.hamburger.stroke}
        isActive={isActive}
        toggleButton={toggleActive}
        buttonWidth={20}
      />
    </div>
  )
}
