import React, { FC } from 'react'
import { motion } from 'framer-motion'
import styles from './styles'
import { useThemeHook } from '@/hooks/useThemeHook'
import { Links } from '@/app/organisms/navigation/components/links'
import { SocialLinks } from '../socialLinks/socialLinks'
import { css } from '@emotion/core'

type DrawerProps = {
  isOpen: boolean
  setIsOpen?: (isOpen: boolean) => void
  location: string
}

export const Drawer: FC<DrawerProps> = ({ isOpen, setIsOpen, location }) => {
  const variants = {
    open: { y: 0 },
    closed: {
      y: '-100%',
    },
  }
  const theme = useThemeHook()
  return (
    <motion.div
      variants={variants}
      animate={isOpen ? 'open' : 'closed'}
      initial="closed"
      css={styles.drawer(theme)}
      transition={{
        type: 'spring',
        damping: 12,
        mass: 0.6,
        stiffness: 80,
        restSpeed: 0.01,
        restDelta: 0.01,
      }}
    >
      <div css={styles.drawerContent}>
        <Links
          location={location}
          isMobile={true}
          callback={() => setIsOpen?.(false)}
        />
        <div
          css={css`
            padding: 32px;
          `}
        >
          <SocialLinks color="primary" />
        </div>
      </div>
    </motion.div>
  )
}
