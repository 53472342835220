import React, { FC } from 'react'
import { Hamburger } from '@/app/atoms/hamburger/hamburger'
import styles from './styles'
import { useThemeHook } from '@/hooks/useThemeHook'
import { Links } from './components/links'

type NavigationProps = {
  isMobile: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  location: string
}
export const Navigation: FC<NavigationProps> = ({
  isMobile,
  isOpen,
  setIsOpen,
  location,
}) => {
  const theme = useThemeHook()

  return (
    <nav css={styles.navigation(theme)}>
      {isMobile ? (
        <Hamburger isActive={isOpen} toggleActive={() => setIsOpen(!isOpen)} />
      ) : (
        <Links location={location} isMobile={false} />
      )}
    </nav>
  )
}
