import { css } from '@emotion/core'

export default {
  content: css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  `,
  layoutContainer: css`
    width: 100vw;
    display: flex;
    justify-content: center;
  `,
  layoutContent: css`
    width: 100%;
    max-width: 2100px;
    min-width: 320px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  `,
}
