import { values } from './themeValues'

export const theme = {
  background: values.colors.white,
  foreground: values.colors.greyScale.light,
  navigation: {
    background: values.colors.white,
    color: values.colors.white,
    height: '60px',
    hamburger: {
      backgroundColor: values.colors.greyScale.light,
      borderRadius: values.radius.circle,
      stroke: values.colors.greyScale.mediumDark,
    },
  },
  button: {
    primary: values.colors.primaryBrand,
    success: values.colors.success,
    warning: values.colors.warning,
    danger: values.colors.danger,
    borderRadius: values.radius.md,
    shadow: values.shadows.light,
    fontColor: values.colors.greyScale.xDark,
  },
  inputs: {
    backgroundColor: '#DADBDD',
    radius: '6px',
    activeColor: values.colors.primaryBrand,
    status: {
      success: values.colors.success,
      error: values.colors.danger,
    },
    fontColor: values.colors.greyScale.mediumDark,
  },
  icons: {
    circleBackground: values.colors.greyScale.light,
    primaryFill: values.colors.greyScale.medium,
  },
  font: {
    heroTitle: {
      size: '64px',
      weight: 700,
    },
    title: {
      size: '52px',
      weight: 500,
    },
    subtitle: {
      size: '32px',
      weight: 500,
    },
    body: {
      size: '16px',
      weight: 300,
    },
    caption: {
      size: '14px',
      weight: 400,
    },
    footnote: {
      size: '12px',
      weight: 300,
    },
    family: values.text.family,
    primaryHeadingColor: values.colors.greyScale.dark,
    inverseHeadingColor: values.colors.white,
    primaryBodyColor: values.colors.greyScale.mediumDark,
    inverseBodyColor: values.colors.white,
  },
  mediaQueries: {
    // Mobile
    xSmall: 0,
    // Large Mobile
    small: 576,
    // Tablet
    medium: 768,
    // Large Tablet
    large: 992,
    // Desktop
    xLarge: 1200,
  },
  spacing: {
    x_5: `${values.spacing(0.5)}`,
    x1: `${values.spacing(1)}`,
    x1_5: `${values.spacing(1.5)}`,
    x2: `${values.spacing(2)}`,
    x2_5: `${values.spacing(2.5)}`,
    x3: `${values.spacing(3)}`,
    x3_5: `${values.spacing(3.5)}`,
    x4: `${values.spacing(4)}`,
    x5: `${values.spacing(5)}`,
    x6: `${values.spacing(6)}`,
    x7: `${values.spacing(7)}`,
    x8: `${values.spacing(8)}`,
  },
  shadows: {
    dark: values.shadows.dark,
    mediumDark: values.shadows.mediumDark,
    mediumlight: values.shadows.mediumlight,
    light: values.shadows.light,
  },
}

export type ThemeTypes = typeof theme
