import { css } from '@emotion/core'

export default {
  socialList: css`
    margin: 0;
    padding: 0;
    list-style: none;
    width: 120px;
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
  `,
}
