import { useThemeHook } from '@/hooks/useThemeHook'
import React from 'react'
import { Text } from '@/app/atoms/text/text'
import styles from './styles'
import { Links } from '@/app/organisms/navigation/components/links'
import { SocialLinks } from '../socialLinks/socialLinks'

export const Footer = () => {
  const theme = useThemeHook()
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer css={styles.footer}>
      <div css={styles.linkContainer}>
        <Links hasActiveState={false} isMobile={false} />
        <Text type="caption" inverseColor>
          <SocialLinks color="inverse" />
        </Text>
      </div>
      <div css={styles.copyright}>
        <Text type="caption">Copyright {year} Tactical Tile LLC</Text>
      </div>
    </footer>
  )
}
