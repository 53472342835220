import { useThemeHook } from '@/hooks/useThemeHook'
import React, { FC } from 'react'
import styles from './styles'

type TextType =
  | 'heroTitle'
  | 'title'
  | 'subTitle'
  | 'body'
  | 'caption'
  | 'footnote'
export type Weight = 'regular' | 'bold' | 'semi-bold'
type TextProps = {
  type: TextType
  inverseColor?: boolean
  alignment?: 'center' | 'left' | 'right'
  weight?: Weight
}
export const Text: FC<TextProps> = ({
  type,
  children,
  inverseColor = false,
  alignment = 'left',
  weight = 'regular',
}) => {
  const theme = useThemeHook()
  const getText = (type: TextType) => {
    switch (type) {
      case 'heroTitle':
        return (
          <h1 css={styles.heroTitle(theme, inverseColor, alignment, weight)}>
            {children}
          </h1>
        )
      case 'title':
        return (
          <h2 css={styles.title(theme, inverseColor, alignment, weight)}>
            {children}
          </h2>
        )
      case 'subTitle':
        return (
          <h3 css={styles.subTitle(theme, inverseColor, alignment, weight)}>
            {children}
          </h3>
        )
      case 'body':
        return (
          <p css={styles.body(theme, inverseColor, alignment, weight)}>
            {children}
          </p>
        )
      case 'caption':
        return (
          <span css={styles.caption(theme, inverseColor, alignment, weight)}>
            {children}
          </span>
        )
      case 'footnote':
        return (
          <span css={styles.footnote(theme, inverseColor, alignment, weight)}>
            {children}
          </span>
        )
    }
  }
  return getText(type)
}
