import React, { FC, useState, useEffect, createContext } from 'react'
import { ThemeProvider } from 'emotion-theming'
import { Global, css } from '@emotion/core'
import { theme } from '@/app/theme/theme'
import { Header } from '@/app/molecules/header/header'
import { Footer } from '@/app/molecules/footer/footer'
import { Drawer } from '@/app/molecules/drawer/drawer'
import { Content } from './components/content'
import { SEO } from './components/seo'
import { Navigation } from '../navigation/navigation'
import { useWindowSize } from '@/hooks/useWindowSize'
import styles from './styles'

type LayoutProps = {
  location: string
}

export const LayoutContext = createContext({
  isMobile: false,
})

export const Layout: FC<LayoutProps> = ({ children, location }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const pageLoadSize = typeof window !== 'undefined' && window.innerWidth
  const [isMobile, setIsMobile] = useState(
    pageLoadSize < theme.mediaQueries.medium,
  )
  const { width } = useWindowSize()

  useEffect(() => {
    if (typeof width === 'number') {
      width < theme.mediaQueries.medium ? setIsMobile(true) : setIsMobile(false)
    }
  }, [isMobile, width])
  return (
    <LayoutContext.Provider value={{ isMobile }}>
      <ThemeProvider theme={theme}>
        <Global
          styles={css`
            * {
              box-sizing: border-box;
            }
            @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
            html,
            body {
              padding: 0;
              margin: 0;
              line-height: 1.65;
              font-family: ${theme.font.family};
              color: #333;
            }
          `}
        />
        <div css={styles.layoutContainer}>
          <div css={styles.layoutContent}>
            <Header toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}>
              <Navigation
                location={location}
                isOpen={isDrawerOpen}
                setIsOpen={setIsDrawerOpen}
                isMobile={isMobile}
              />
            </Header>
            <Drawer
              location={location}
              isOpen={isDrawerOpen}
              setIsOpen={setIsDrawerOpen}
            />
            <Content>{children}</Content>
            <Footer />
          </div>
        </div>
      </ThemeProvider>
    </LayoutContext.Provider>
  )
}
