import { ThemeTypes } from '@/app/theme/theme'
import { css } from '@emotion/core'

export default {
  footer: css`
    position: relative;
    min-width: 320px;
    width: 100%;
    height: 240px;
    padding: 16px 32px;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  socialList: css`
    margin: 0;
    padding: 0;
    list-style: none;
    width: 120px;
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
  `,
  linkContainer: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `,
  copyright: css`
    position: absolute;
    bottom: 8px;
    left: 8px;
  `,
}
