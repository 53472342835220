import { ThemeTypes } from '@/app/theme/theme'
import { css } from '@emotion/core'

export default {
  drawer: (theme: ThemeTypes) => css`
    height: calc(100vh + 60px);
    width: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.background};
    z-index: 99;
  `,
  drawerContent: css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
}
