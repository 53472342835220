import React, { FC } from 'react'
import { useThemeHook } from '@/hooks/useThemeHook'
import styles from './styles'

type HeaderProps = {
  toggleDrawer: () => void
}
export const Header: FC<HeaderProps> = ({ children }) => {
  const theme = useThemeHook()
  return <header css={styles.header(theme)}>{children}</header>
}
