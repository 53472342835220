import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ContactInfoQuery_Manual } from './../../../../manual-graphql-types'
import { Text } from '@/app/atoms/text/text'
import { Facebook } from '@/app/atoms/icons/facebook'
import { Instagram } from '@/app/atoms/icons/instagram'
import { LinkedIn } from '@/app/atoms/icons/linkedIn'
import styles from './styles'
import { useThemeHook } from '@/hooks/useThemeHook'

type SocialLinksProps = {
  color: 'primary' | 'inverse'
}
export const SocialLinks: FC<SocialLinksProps> = ({ color }) => {
  const data: ContactInfoQuery_Manual = useStaticQuery(graphql`
    query ContactInfo {
      allSanityContactDetails {
        nodes {
          instagram {
            _key
            _type
            handle
            accountURL
          }
          linkedIn {
            _key
            _type
            handle
            accountURL
          }
          facebook {
            _key
            _type
            handle
            accountURL
          }
        }
      }
    }
  `)
  const socialLinks = data.allSanityContactDetails.nodes[0]
  const theme = useThemeHook()
  const iconColor =
    color === 'inverse'
      ? theme.font.inverseHeadingColor
      : theme.font.primaryHeadingColor

  return (
    <Text type="caption" inverseColor={color === 'inverse' ? true : false}>
      <ul css={styles.socialList}>
        <a href={socialLinks.facebook.accountURL}>
          <Facebook color={iconColor} />
        </a>
        <a href={socialLinks.instagram.accountURL}>
          <Instagram color={iconColor} />
        </a>
        <a href={socialLinks.linkedIn.accountURL}>
          <LinkedIn color={iconColor} />
        </a>
      </ul>
    </Text>
  )
}
