import React, { FC, useState } from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { Text } from '@/app/atoms/text/text'
import styles from '../styles'
import { useThemeHook } from '@/hooks/useThemeHook'
import { AnimateSharedLayout, motion } from 'framer-motion'
import About from '@/pages/about'

type LinksProps = {
  isMobile: boolean
  callback?: () => void
  location?: string
  hasActiveState?: boolean
}
export const Links: FC<LinksProps> = ({
  isMobile,
  callback,
  location,
  hasActiveState = true,
}) => {
  const theme = useThemeHook()

  const links = [
    { name: 'Home', to: '/' },
    { name: 'About', to: '/about' },
    { name: 'Work', to: '/work' },
    { name: 'Contact', to: '/contact' },
  ]

  return (
    <AnimateSharedLayout>
      <ul css={styles.linkContainer(isMobile)}>
        {links.map(link => {
          const isActive = link.to === location
          return (
            <Link
              key={link.to}
              css={styles.link(theme, isMobile)}
              onClick={callback}
              to={link.to}
            >
              {hasActiveState ? (
                <>
                  <motion.div layout>
                    <Text
                      type={
                        isMobile
                          ? isActive
                            ? 'title'
                            : 'subTitle'
                          : isActive
                          ? 'body'
                          : 'caption'
                      }
                    >
                      {link.name}
                    </Text>
                  </motion.div>
                  {isActive && (
                    <motion.div
                      css={css`
                        height: 2px;
                        background: ${theme.button.primary};
                        border-radius: 4px;
                      `}
                      layoutId="activeLink"
                    ></motion.div>
                  )}
                </>
              ) : (
                <Text type="caption">{link.name}</Text>
              )}
            </Link>
          )
        })}
      </ul>
    </AnimateSharedLayout>
  )
}
