import React from 'react'

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match

  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  type WindowState<T> = {
    width: T
    height: T
  }
  const [windowSize, setWindowSize] = React.useState<
    WindowState<undefined | number>
  >({
    width: undefined,
    height: undefined,
  })

  React.useEffect(() => {
    // Handler to call on window resize

    function handleResize() {
      // Set window width/height to state

      setWindowSize({
        width: window.innerWidth,

        height: window.innerHeight,
      })
    }

    // Add event listener

    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size

    handleResize()

    // Remove event listener on cleanup

    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  const { width, height } = windowSize
  return {
    width,
    height,
  }
}
