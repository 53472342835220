import { css } from '@emotion/core'
import { ThemeTypes } from '@/app/theme/theme'

export default {
  header: (theme: ThemeTypes) => css`
    height: 60px;
    width: 100%;
    background: ${theme.navigation.background};
    color: ${theme.navigation.color};
    box-shadow: ${theme.shadows.light};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  `,
}
