import { ThemeTypes } from '@/app/theme/theme'
import { css } from '@emotion/core'

export default {
  navigation: (theme: ThemeTypes) => css`
    padding: 0 ${theme.spacing.x1};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,
  link: (theme: ThemeTypes, isMobile: boolean) => css`
    color: ${theme.font.primaryBodyColor};
    text-decoration: none;
    padding: ${isMobile ? `${theme.spacing.x1} 0` : `0 ${theme.spacing.x1}`};
    font-size: ${isMobile ? theme.font.subtitle.size : theme.font.caption.size};
    font-weight: 300;
  `,
  linkContainer: (isMobile: boolean) => css`
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    justify-content: ${isMobile ? 'center' : 'flex-end'};
    align-items: center;
    margin: 0;
    padding: 0;
  `,
}
