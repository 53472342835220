import { ThemeTypes } from '@/app/theme/theme'
import { css } from '@emotion/core'

export default {
  hamburgerBackground: (theme: ThemeTypes) => css`
    background-color: ${theme.icons.circleBackground};
    width: 40px;
    height: 40px;
    border-radius: ${theme.navigation.hamburger.borderRadius};
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  `,
}
