const colors = {
  black: '#000000',
  primaryBrand: '#0099FF',
  white: '#FFFFFF',
  greyScale: {
    light: '#eeeeee',
    mediumLight: '#cccccc',
    medium: '#999999',
    mediumDark: '#666666',
    dark: '#333333',
    xDark: '#000000',
  },
  success: '#00CC88',
  danger: '#FF3366',
  warning: '#F8CE52',
}

const radius = {
  none: '0px',
  xs: '2px',
  sm: '4px',
  md: '6px',
  lg: '8px',
  xl: '12px',
  fat: '100px',
  circle: '1000rem',
}

const shadows = {
  dark: '0px 16px 32px 0px rgba(0,0,0,0.12)',
  mediumDark: '0px 8px 16px 0px rgba(0,0,0,0.1)',
  mediumlight: '0px 4px 8px 0px rgba(0,0,0,0.08)',
  light: '0px 2px 4px 0px rgba(0,0,0,0.08)',
}

const text = {
  family: 'Poppins, sans-serif',
}

const spacing = (multiplier: number) => {
  const toPixels = `${multiplier * 8}px`
  return toPixels
}
export const values = {
  colors,
  radius,
  text,
  shadows,
  spacing,
}
