import { ThemeTypes } from '@/app/theme/theme'
import { css } from '@emotion/core'
import { Weight } from './text'

export default {
  heroTitle: (
    theme: ThemeTypes,
    inverseColor: boolean,
    alignment: 'left' | 'center' | 'right',
    weight?: Weight,
  ) => css`
    font-size: ${theme.font.heroTitle.size};
    font-weight: ${theme.font.heroTitle.weight};
    color: ${inverseColor
      ? theme.font.inverseHeadingColor
      : theme.font.primaryHeadingColor};
    margin: 0;
    text-align: ${alignment};
    font-weight: ${weight === 'bold'
      ? 700
      : weight === 'semi-bold'
      ? 600
      : 500};
  `,
  title: (
    theme: ThemeTypes,
    inverseColor: boolean,
    alignment: 'left' | 'center' | 'right',
    weight?: Weight,
  ) => css`
    font-size: ${theme.font.title.size};
    font-weight: ${theme.font.title.weight};
    color: ${inverseColor
      ? theme.font.inverseHeadingColor
      : theme.font.primaryHeadingColor};
    margin: 0;
    text-align: ${alignment};
    font-weight: ${weight === 'bold'
      ? 700
      : weight === 'semi-bold'
      ? 600
      : 500};
  `,
  subTitle: (
    theme: ThemeTypes,
    inverseColor: boolean,
    alignment: 'left' | 'center' | 'right',
    weight?: Weight,
  ) => css`
    font-size: ${theme.font.subtitle.size};
    font-weight: ${theme.font.subtitle.weight};
    color: ${inverseColor
      ? theme.font.inverseHeadingColor
      : theme.font.primaryHeadingColor};
    margin: 0;
    text-align: ${alignment};
    font-weight: ${weight === 'bold'
      ? 700
      : weight === 'semi-bold'
      ? 600
      : 500};
  `,
  body: (
    theme: ThemeTypes,
    inverseColor: boolean,
    alignment: 'left' | 'center' | 'right',
    weight?: Weight,
  ) => css`
    font-size: ${theme.font.body.size};
    font-weight: ${theme.font.body.weight};
    color: ${inverseColor
      ? theme.font.inverseBodyColor
      : theme.font.primaryBodyColor};
    margin: 0;
    text-align: ${alignment};
    font-weight: ${weight === 'bold'
      ? 700
      : weight === 'semi-bold'
      ? 500
      : 300};
  `,
  caption: (
    theme: ThemeTypes,
    inverseColor: boolean,
    alignment: 'left' | 'center' | 'right',
    weight?: Weight,
  ) => css`
    font-size: ${theme.font.caption.size};
    font-weight: ${theme.font.caption.weight};
    color: ${inverseColor
      ? theme.font.inverseBodyColor
      : theme.font.primaryBodyColor};
    margin: 0;
    text-align: ${alignment};
    font-weight: ${weight === 'bold'
      ? 700
      : weight === 'semi-bold'
      ? 500
      : 300};
  `,
  footnote: (
    theme: ThemeTypes,
    inverseColor: boolean,
    alignment: 'left' | 'center' | 'right',
    weight?: Weight,
  ) => css`
    font-size: ${theme.font.footnote.size};
    font-weight: ${theme.font.footnote.weight};
    color: ${inverseColor
      ? theme.font.inverseBodyColor
      : theme.font.primaryBodyColor};
    margin: 0;
    text-align: ${alignment};
    font-weight: ${weight === 'bold'
      ? 700
      : weight === 'semi-bold'
      ? 500
      : 300};
  `,
}
